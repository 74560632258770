
import { reportUserTokenError } from '@/services/utils/telemetry';
import { userGateway } from '../api/endpoints';
import { fechApiWithAuthToken, getLibertyClient } from '../clients/liberty';
import { ENDPOINT } from '@/const/http';

// export const getUserPermissionList = async () => {
//     const userContextClient = await getLibertyClient(userGateway);
    
//     try {
//         const response = await userContextClient.get(`permissions`);
//         return response.data;
//     }
//     catch (error) {
//         const endpoint = `${userGateway}/permissions`;
//         reportError(error, {
//             endpoint: `${userGateway}/permissions`
//         });
//         const token = userContextClient.defaults.headers['Firebase-Token'];
//         reportUserTokenError(endpoint, token, JSON.stringify(error));
//         throw error;
//     }
// }

export const getUserPermissionList = async (retryCount = 1, retryDelay = 1000) => {
    const userContextClient = await getLibertyClient(userGateway);
    console.log('Start sending request to get permissions with endpoint:', ENDPOINT.REACH_API.GET_PERMISSIONS());
    try {
        const response = await fechApiWithAuthToken({mothod: 'GET', url: ENDPOINT.REACH_API.GET_PERMISSIONS()});
        if (response.ok) {
            const data = await response.json();
            return data;
        }
        else {
            console.error('Error fetching permissions from oci due to response failed:', response.status);
            const error = new Error(`Error fetching permissions`);
            reportError(error, {
                endpoint : ENDPOINT.REACH_API.GET_PERMISSIONS(),
                code: response.status
            });
            throw error;
        }
    }
    catch (error) {
        if (retryCount > 0) {
            console.log(`Retrying getUserPermissionList. Attempts remaining: ${retryCount}`);
            // Wait for the specified delay
            await new Promise(resolve => setTimeout(resolve, retryDelay));
            // Retry with one less retry count
            return getUserPermissionList(retryCount - 1, retryDelay);
        }
        console.error(`Error fetching permissions after ${retryCount} attempts:`, error);
        const endpoint = ENDPOINT.REACH_API.GET_PERMISSIONS();
        reportError(error, {
            endpoint
        });
        const token = userContextClient.defaults.headers['Firebase-Token'];
        reportUserTokenError(endpoint, token, JSON.stringify(error));
        throw error;
    }
}