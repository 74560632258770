import * as Sentry from '@sentry/vue'
import { getFunctions, httpsCallable } from 'firebase/functions'
import app from '../firebase/init'
import { getAuth } from 'firebase/auth'

export const reportError = (error, extra) => {
  Sentry.captureException(error, { extra: extra })
}

export const setTelemetryUser = (user) => {
  if (user) {
    Sentry.setUser({
      id: user.id,
      username: user.name,
      email: user.email
    })
  } else {
    Sentry.setUser(null)
  }
}


export const reportUserTokenError = async (endpoint, token, errorJson) => {
  
  const auth = getAuth(app)
  
  try {
    const functions = getFunctions(app, 'australia-southeast1')
    const reportFunction = httpsCallable(functions, 'rubix-reportUserTokenError');
    await reportFunction({ userEmail: auth.currentUser?.email, token, endpoint, errorJson});
  }
  catch (error) {
    reportError(error)
    throw error
  }

  try {
    const user = auth.currentUser;
    await user.getIdToken(true);
  }
  catch (error) {
    reportError(error)
    throw error
  }
}
