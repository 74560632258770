export const HTTP = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
}

const reachApiGateway = import.meta.env.VITE_REACH_API_GATEWAY_URL
const ticketsApiGateway = `${reachApiGateway}/tickets/v1`
const roundApiGateway = `${reachApiGateway}/rounds/v1`
const userApiGateway = `${reachApiGateway}/user/v1`;
const calculatorApiGateway = `${reachApiGateway}/calculator/v1`
const customerApiGateway = `${reachApiGateway}/customer/v1`
const ratesApiGateway = `${reachApiGateway}/rates/v1`
const campaignApiGateway = `${reachApiGateway}/campaign/v1`

const geoGateway = import.meta.env.VITE_GEO_GATEWAY_URL


export const ENDPOINT = {
  REACH_API: {
    // Get list of jobs with start/end date or walk ID
    GET_JOBS: q => `${ticketsApiGateway}/jobsearch?${q}`,
    GET_JOB: id => `${ticketsApiGateway}/jobsearch?job_id=${id}`,
    GET_JOB_BY_CONTRACT_NUMBER: (contractNumber) => `${campaignApiGateway}/${encodeURIComponent(contractNumber)}`,
    GET_TICKETS: () => `${ticketsApiGateway}/`,
    GET_TICKET: id => `${ticketsApiGateway}/${id}`,
    CREATE_TICKET: () => `${ticketsApiGateway}/create`,
    UPDATE_TICKET: id => `${ticketsApiGateway}/${id}`,
    GET_COMMENTS: id => `${ticketsApiGateway}/${id}/comments`,
    CREATE_COMMENT: id => `${ticketsApiGateway}/${id}/comments`,
    GET_ATTACHMENT: id => `${ticketsApiGateway}/attachment/${id}`,
    CREATE_ATTACHMENT: id => `${ticketsApiGateway}/comments/${id}/attachment`,
    GET_ROUND_DETAILS: walkId => `${roundApiGateway}/${walkId}`,
    GET_PERMISSIONS: () => `${userApiGateway}/permissions`,
    GET_PRINT_QUOTES: q => `${calculatorApiGateway}/print?${q}`,
    GET_CUSTOMER: searchTerm => `${customerApiGateway}/search/${searchTerm}`,
    GET_CUSTOMER_CONTACTS: cmsCode =>
      `${customerApiGateway}/contacts/${cmsCode}`,
    GET_LETTERBOX_TOTAL: q => `${calculatorApiGateway}/total?${q}`,
    GET_LETTERBOX_ADMIN_FEE: q => `${ratesApiGateway}/admin?${q}`,
    GET_LETTERBOX_FARMER_QUOTE: q => `${ratesApiGateway}/farmer-premium?${q}`,
    GET_LETTERBOX_FREIGHT_QUOTE: q => `${calculatorApiGateway}/freight?${q}`,
    GET_LETTERBOX_NETWORK_QUOTE: q =>
      `${ratesApiGateway}/network-management?${q}`,
    GET_LETTERBOX_VOLUME_QUOTE: q => `${calculatorApiGateway}/volume?${q}`,
    GET_ACCOUNT_CODE: code => `${customerApiGateway}/by-account-code/${code}`,
    CREATE_STANDALONE_JOB: () => `${campaignApiGateway}/create`,
    GET_DELIVERY_COMPLETION: ({ view, queryParams }) =>
      `${customerApiGateway}/completion/list/${view}?${new URLSearchParams(queryParams).toString()}`,
    GET_DELIVERY_COMPLETION_BY_CMS_CODE: ({ cmsCode, view, queryParams }) =>
      `${customerApiGateway}/${cmsCode}/completion/list/${view}?${new URLSearchParams(queryParams).toString()}`,
    GET_JOBS_INFO: ({ view, queryParams }) =>
      `${customerApiGateway}/jobs/list/${view}?${new URLSearchParams(queryParams).toString()}`,
    GET_JOBS_BY_CMS_CODE: ({ cmsCode, view, queryParams }) =>
      `${customerApiGateway}/${cmsCode}/jobs/list/${view}?${new URLSearchParams(queryParams).toString()}`,
  },

  GEO_SERVICE: {
    GET_ROUND_BY_LATLNG: (lat, lng) =>
      `${geoGateway}/roundByGeoPoint?lat=${lat}&lng=${lng}`,
    GET_ROUND_BOX: () => `${geoGateway}/roundsBoundingBox`,
    GET_ROUND_MAP: () => `${geoGateway}/roundsByList`,
  },

}

export const HTTP_CODES = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  SERVER_ERROR: 500,
}
