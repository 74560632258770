import { getUserPermissionList } from "@/repositories/userContextRepository";
import { defineStore } from "pinia";
import { useMainStore } from "./main";

/**
 * User Context Store
 * Handles state management for user context, roles, and permissions
 */
export const useUserContextStore = defineStore('userContext', {
    state: () => ({
        /** User details object */
        userDetails: null,
        /** Array of user roles */
        userRoles: [],
        /** User permissions object */
        permissions: null,
        /** Loading states for different operations */
        loadingStates: {
            updatingContext: false,
            updatingPermissions: false
        }
    }),

    getters: {
        isPermissionLoaded: (state) => {
            return state.permissions != null;
        },

        isUserHasAppPermision: (state) => {
            const app = ((state.permissions || {}).apps || []).find((a) => 'RUBIX' === a.app);
            return ((app || {}).roles || []).length > 0;
        },
        /**
         * Check if any operation is currently loading
         * @returns {boolean} True if any operation is in loading state
         */
        isLoading: (state) => Object.values(state.loadingStates).some(loading => loading),

        /**
         * Check if user is admin
         * @returns {boolean} True if user has admin role
         */
        isAdmin: (state) => state.userRoles.includes(USER_ROLES.ADMIN),

        /**
         * Get all user roles
         * @returns {Array} Array of user roles
         */
        getUserRoles: (state) => state.userRoles,

        /**
         * Get user permissions
         * @returns {Object} User permissions object
         */
        getPermissions: (state) => state.permissions
    },

    actions: {

        async loadPermissions() {
            console.log('Loading permissions within store...');
            try {
                const response = await getUserPermissionList();
                const mainStore = useMainStore();
                this.permissions = response;
                mainStore.setPermissions(response);
            } catch (error) {
                console.error('Error fetching permissions catched in store:', error);
                throw error;
            }
        },
        /**
         * Reset the store to its initial state
         */
        reset() {
            this.$reset();
        }
    }
});