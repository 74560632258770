import app from "@/services/firebase/init";
import { reportError } from "@/services/utils/telemetry";
import axios from "axios";
import { getAuth } from "firebase/auth";

const getFirebaseTokenHeader = async () => {
    const auth = getAuth(app);
    try {
        const token = await auth.currentUser.getIdToken(true);
        return { 'Firebase-Token': `Bearer ${token}` };
    }
    catch (error) {
        reportError(error);
        console.error('Error getting token:', error);
        throw error;
    }
};

export const getLibertyClient = async (baseURL) => {
    try {
        return axios.create({
            baseURL,
            headers: {
                'Content-Type': 'application/json',
                ...(await getFirebaseTokenHeader())
            }
        });
    }
    catch (error) {
        reportError(error);
        console.error('Error creating client:', error);
        throw error;
    }
}

export const fechApiWithAuthToken = async ({ method, url, payload}) => {
    const headers = {
        'Content-Type': 'application/json',
        ...(await getFirebaseTokenHeader())
    };

    const options = {
        method,
        mode: 'cors',
        headers,
        ...(payload && { body: JSON.stringify(payload) })
    }
    return await fetch(url, options);
}
