export const AUTH_UNKNOWN = 'UNKNOWN'
export const AUTHENTICATED = 'AUTHENTICATED'
export const UNAUTHENTICATED = 'UNAUTHENTICATED'

export const LOGIN_STATE = {
  WAITING_FOR_EMAIL: 'WAITING_FOR_EMAIL',
  EMAIL_LINK_SENT: 'EMAIL_LINK_SENT',
  REDIRECTING: 'REDIRECTING'
}

const REACH_DOMAIN = 'reach.nz';

export const CUSTOM_OAUTH_PARAMETERS = {
  hd: `${REACH_DOMAIN}`,
  prompt: 'select_account'
}

export const USER_ROLES = {
  ORG_ADMIN: 'ORG_ADMIN',
  CALL_CENTRE: 'CALL_CENTR',
  REACH: 'REACH'
}

export const GOOGLE_SIGN_IN_DOMAINS = [REACH_DOMAIN];

export const PERMISSION = {
  BRIEF: 'BRIEF',
  QUOTE: 'QUOTE',
  CALCULATOR: 'CALCULATOR',
  DELIVERY_REPORT: 'DELIVERY_REPORT',
  TICKETS: 'TICKETS',
  DELIVERY_STATUS: 'DELIVERY_STATUS',
  CHECK_DELIVERY: 'CHECK_DELIVERY'
};