import { USER_ROLES } from '@/const/auth'
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useMainStore = defineStore('main', () => {
  const userName = ref('')
  const userEmail = ref('')
  const permissions = ref(null)
  const customerContext = ref(null)

  const customerContexts = computed(() => {
    if (!permissions.value?.apps) return []

    return permissions.value.apps.flatMap((app) => {
      if (app.app === 'RUBIX' && app.customer?.cust_no) {
        return app.customer
      }

      return []
    })
  })
  const selectedCustomer = computed(() => {
    return customerContext.value ?? customerContexts.value[0]
  })

  const hasAppPermission = computed(() => {
    if (!permissions.value?.apps) return false

    return (
      permissions.value.apps.find((p) => p.app && p.app.toUpperCase() === 'RUBIX') !== undefined
    )
  })

  const userRoles = computed(() => {
    if (!hasAppPermission.value) return []

    const roles = []

    permissions.value.apps.forEach((app) => {
      if (app.app === 'RUBIX') {
        app.roles.forEach((r) => roles.push(r.role.toUpperCase()))
      }
    })

    return roles
  })

  const userAvatar = computed(
    () =>
      `https://api.dicebear.com/7.x/avataaars/svg?seed=${userEmail.value.replace(
        /[^a-z0-9]+/gi,
        '-'
      )}`
  )

  const isFieldFocusRegistered = ref(false)

  const isNewspaperCustomer = computed(() => {
    const permission = permissions.value.apps.find((p) => p.app?.toUpperCase() === 'RUBIX')

    if (permission) {
      return permission.customer?.is_newspaper ?? false
    }
    return false
  })

  const isReachUser = computed(() => hasFeatureRoles([USER_ROLES.REACH]))

  function hasFeatureRoles(roles) {
    return userRoles.value.some((r) => roles.includes(r))
  }

  function setCustomerContext(context) {
    customerContext.value = context
  }

  function setPermissions(newPermissions) {
    permissions.value = newPermissions
  }

  function setUser(payload) {
    if (payload.name) {
      userName.value = payload.name
    }
    if (payload.email) {
      userEmail.value = payload.email
    }
  }

  const permittedFunctions = computed(() => {
    const app = (permissions.value?.apps || []).find((p) => p.app && p.app.toUpperCase() === 'RUBIX');
    const f = ((app || {}).roles || []).flatMap(r => r.functions).map(f => f.function);
    return f;
  })
  
  function $reset() {
    userEmail.value = ''
    userName.value = ''
    permissions.value = null
  }

  return {
    customerContext,
    customerContexts,
    permissions,
    selectedCustomer,
    userName,
    userEmail,
    userAvatar,
    userRoles,
    isFieldFocusRegistered,
    isNewspaperCustomer,
    isReachUser,
    hasAppPermission,
    hasFeatureRoles,
    setCustomerContext,
    setPermissions,
    setUser,
    permittedFunctions,
    $reset
  }
})
