import { AUTH_UNKNOWN, CUSTOM_OAUTH_PARAMETERS } from '@/const/auth'
import { ROUTES } from '@/const/route'
import { reportError, setTelemetryUser } from '@/services/utils/telemetry'
import { useMainStore } from '@/stores/main'
import {
  GoogleAuthProvider,
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { reactive } from 'vue'
import app from './init'

const auth = getAuth(app)
const functions = getFunctions(app, 'australia-southeast1')

const authState = reactive({
  auth,
  authenticated: AUTH_UNKNOWN,
  loading: true,
  isTokenHasClaim: false,
})

const getCurrentUser = () => {
 
  return new Promise((resolve, reject) => {
    const unsub = auth.onAuthStateChanged(user => {
      if (user) {
        setTelemetryUser({
          email: user.email,
          id: user.uid,
          name: user.displayName,
        })

        const mainStore = useMainStore()
        mainStore.setUser({
          email: user.email,
          name: user.displayName ?? user.email,
        })
      }
      resolve(user)
      unsub()
    }, (error) => {
      reject(error)
    },)
  })
}

const signIn = async () => {
  const provider = new GoogleAuthProvider()
  provider.setCustomParameters(CUSTOM_OAUTH_PARAMETERS)

  try {
    await signInWithRedirect(auth, provider)
    // await signInWithPopup(auth, provider) // Use this in localhost in case of redirect loop

  } catch (error) {
    reportError(error)
    throw error
  }

  await addClaimToToken();
}

const signInWithEmail = email => {
  return signInWithEmailLink(auth, email, window.location.href)
}

const requestEmailLink = async (email, redirectUrl) => {
  if (email) {
    const getSignInLink = httpsCallable(
      functions,
      'rubix-getCustomerDashboardSignInLink',
    )
    await getSignInLink({ email, redirectUrl })
    window.localStorage.setItem('emailForSignIn', email)
  }
}

const isEmailSignInLink = url => {
  return isSignInWithEmailLink(auth, url)
}

const isUserSignedIn = async () => {
  if (auth.currentUser) {
    return true
  }
  try {
    return (await getCurrentUser()) !== null
  } catch (error) {
    reportError(error)
    return false
  }
}

const userSignOut = () => {
  signOut(auth)
    .then(() => {
      const mainStore = useMainStore()
      mainStore.$reset()

      authState.authenticated = AUTH_UNKNOWN
      setTelemetryUser(null)
      window.localStorage.clear()
      window.location.replace(`${ROUTES.BASE}${ROUTES.LOGIN}`)
    })
    .catch(error => reportError(error))
}

export const addClaimToToken = async () => {
  const user = getAuth(app).currentUser;

  if (!user?.uid) {
    reportError(`User id not found with email: ${user?.email}`);
    throw new Error('User id not found');
  }
  try {
    const addClaimFunction = httpsCallable(functions, 'rubix-addRubixClaim');
    await addClaimFunction({ uid: user.uid, userEmail: user?.email });
  }
  catch (error) {
    reportError(error)
    throw error
  }

  try {
    await user.getIdToken(true);
  }
  catch (error) {
    reportError(error)
    throw error
  }
}

// export const removeClaim = async () => {
//   const user = getAuth(app).currentUser;

//   if (!user?.uid) {
//     throw new Error('User id not found');
//   }
//   try {

//     const removeClaim = httpsCallable(functions, 'rubix-removeCustomClaim');
//     await removeClaim({uid: user.uid});
//   }
//   catch (error) {
//     reportError(error)
//     throw error
//   }

//   try {
//     await user.getIdToken(true);
//   }
//   catch (error) {
//     reportError(error)
//     throw error
//   }
// }


export const checkUserClaims = async () => {
  
  if (authState.isTokenHasClaim) {
    return true;
  }
  const user = auth.currentUser;
  try {
    const idTokenResult = await user?.getIdTokenResult(true);
    if (idTokenResult?.claims?.app === 'RUBIX') {
      authState.isTokenHasClaim = true;
      return true;
    }
    
    return false;
  }
  catch (error) {
    reportError(error, {
      description: 'Error while checking user claims',
    })
    throw error
  }
}

export const useAuth = () => {
  return {
    authState,
    getCurrentUser,
    signIn,
    signInWithEmail,
    isEmailSignInLink,
    isUserSignedIn,
    requestEmailLink,
    userSignOut,
  }
}
